<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      {{ snackbarMessage }}
    </v-snackbar>
  <v-card class="card-shadow border" color="white">
    <div>
      <v-row align="end" class="pa-8" style="gap: 30px">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
        <v-btn color="primary" @click.prevent="currentPage = 1; Business(search)">Search</v-btn>
      </v-row>
      <v-data-table :headers="headers" :items="BusinessData" hide-default-footer :loading="loading"
        :loading-text="message" :items-per-page="currentRows">
        <template v-slot:item.action="{ item }">
          <v-flex style="display: flex;">
            <v-menu offset-y >
              <template class="ma-2" v-slot:activator="{ on, attrs }">
                <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
                  {{ item.action }} <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click='handleModal(item)'>
                  <v-list-title>Edit</v-list-title>
                </v-list-item>
                <v-list-item link @click='handleRedirect(item.id)'>
                  <v-list-title>Accounts</v-list-title>
                </v-list-item>
                <v-list-item link @click='handleAccounts(item.id)'>
                  <v-list-title>Linked Accounts</v-list-title>
                </v-list-item>
                <v-list-item v-if="item.kyb_not_approved" link @click='handleApproveKYB(item.id)'>
                  <v-list-title>Approve KYB</v-list-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-btn small @click="handleRedirect(item.id)" class="ma-2" outlined color="primary">
              {{ item.action }}
            </v-btn> -->
            <!-- <v-btn small class="ma-2" outlined color="primary" @click="handleAccounts(item.id)">
              <v-icon>mdi-account-multiple</v-icon>
            </v-btn> -->
            <!-- <v-btn small class="ma-2" outlined color="primary" style="margin-left: 15px;" @click="handleModal(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn> -->
          </v-flex>
        </template>
      </v-data-table>
      <v-row align="center" justify="end">
        <v-col cols="auto">
          <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
        </v-col>
        <v-col cols="auto" style="min-width: 400px;">
          <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
            :total-visible="7" />
        </v-col>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="650px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Business Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field label="Name" v-model="bName" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Legal Name" v-model="bLegalName" required />
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-text-field label="Email" v-model="bEmail" required />
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-text-field label="Kyb Status" v-model="bKyb_status" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Phone" v-model="bPhone" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Address 1" v-model="bAdd1" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Address 2" v-model="bAdd2" required />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="City" v-model="bCity" required />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="State" v-model="bState" required />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Country" v-model="bCountry" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Zip code" v-model="bZip_code" required />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field label="Website" v-model="bWebsite" required />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="success" @click.prevent="editBusiness()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="accounts_dialog" max-width="400px">
        <v-card class="pa-4">
          <v-row class="justify-space-between align-center mb-5">
            <h5 class="text-h6 col-auto">Linked Accounts</h5>
            <p v-if="account_detail" class="col-auto mb-0">{{ account_detail.length }}</p>
          </v-row>
          <v-flex class="d-flex justify-center">
            <v-progress-circular indeterminate color="primary" v-if="account_loading"></v-progress-circular>
          </v-flex>
          <div style="max-height: 50vh; overflow: auto;" v-if="account_detail">
            <li v-for="item in account_detail" :key="item.account_no"
              style="list-style: none; border-bottom: 1px solid gray;" class="py-2">
              <h5 class="text-h6 mb-3">{{ item.label }}</h5>
              <p>Account No: {{ item.account_number }}</p>
              <p>Payment Type: {{ item.payment_type }}</p>
              <p>Bank Name: {{ item.bank_name }}</p>
              <p>Account Type: {{ item.account_type }}</p>
              <p>Legal Name: {{ item.legal_name }}</p>
              <p>Routing No: {{ item.routing_number }} </p>
            </li>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn color="green darken-1" text @click="accounts_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
  </div>
</template>
<script>

export default {
  data() {
    return {
      currentPage: 1,
      noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50],
      currentRows: 50,

      BusinessData: [],
      data: '',
      lastDynamicRoute: null,
      search: '',
      message: 'Loading... Please wait',
      loading: true,
      timeout: 2000,
      snackbar: false,
      snackbarColor: 'green',
      snackbarMessage: null,
      headers: [
        { text: 'Name', value: 'legal_name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Website', value: 'website' },
        { text: 'Entity Type', value: 'entity_type'},
        { text: 'Address', value: 'address' },
        { text: 'Zip Code', value: 'zip_code' },
        { text: 'Kyb Status', value: 'kyb_status' },
        {text: 'Created At', value: 'created_at'},
        { text: 'Action', value: 'action' },
      ],
      modalData: null,

      abortController: new AbortController(),

      // this is for edit modal 
      dialog: false,
      bId: '',
      bName: '',
      bLegalName: '',
      bEmail: '',
      bPhone: '',
      bAdd1: '',
      bAdd2: '',
      bCity: '',
      bState: '',
      bCountry: '',
      bZip_code: '',
      bKyb_status: '',
      bWebsite: '',
      // end here 

      accounts_dialog: false,
      account_detail: [{ label: '', account_no: '' }],
      account_loading: false
    };
  },
  props: {
    currentServer: Object,
  },
  methods: {
    async Business(searchString) {
      try {
        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        const userId = this.$route.params.id ? '&user_id=' + this.$route.params.id : '';
        const SearchString = searchString ? '&search_string=' + searchString : '';

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(
          `${baseurl}admin/businesses${v_page + v_per_page + userId + SearchString}`,
          {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
            signal,
          }
        );
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        const mainData = data.data;
        const newArray = mainData?.map((item) => {
          return {
            id: item?.id,
            legal_name: item?.attributes?.legal_name,
            name: item?.attributes?.name,
            kyb_status: item?.attributes?.kyb_status,
            kyb_not_approved: item?.attributes?.kyb_status != 'approved',
            entity_type: item?.attributes?.entity_type,
            email: item?.attributes?.email,
            phone: item?.attributes?.phone,
            website: item?.attributes?.website,
            user_id: item?.attributes?.user_id,
            address: (item?.attributes?.address_1 ?? '') + (item?.attributes?.address_2 ? ', ' + item?.attributes?.address_2 : '') + (item?.attributes?.city ? ', ' + item?.attributes?.city : '') + (item?.attributes?.state ? ', ' + item?.attributes?.state : '') + (item?.attributes?.country ? ', ' + item?.attributes?.country : ''),
            address1: item?.attributes?.address_1,
            address2: item?.attributes?.address_2,
            city: item?.attributes?.city,
            state: item?.attributes?.state,
            country: item?.attributes?.country,
            zip_code: item?.attributes?.zip_code,
            created_at: this.formatDate(new Date(item?.attributes?.created_at)).toLocaleString('en-US', {timeZone: 'America/New_York'}),
            action: 'Actions'
          };
        });
        this.BusinessData = newArray;

        this.noOfPages = Math.ceil(parseInt(data?.total_count) / this.currentRows)
        this.loading = false

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.name === 'AbortError') {
          // Request was cancelled
          this.loading = true
          this.message = 'Loading... Please wait'
        } else if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.loading = false
          this.message = 'something went wrong'
        }
      }
    },
    async LinkedAccounts(businessId) {
      this.account_loading = true
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
      const baseurl = this.currentServer?.baseUrl
      try {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(
          `${baseurl}admin/ach_link_accounts?business_id=${businessId}&page=1&per_page=100`,
          {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
          }
        );
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }

        const data = await response.json();
        const mainData = data.data;
        this.account_detail = mainData.map((item) => {
          return {
            legal_name: item.attributes.legal_name,
            account_type: item.attributes.account_type,
            bank_name: item.attributes.bank_name,
            account_number: item.attributes.account_number,
            label: item.attributes.label,
            payment_type: item.attributes.payment_type,
            routing_number: item.attributes.routing_number,
          }
        });
        this.account_loading = false
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.account_loading = true
        }
      }
    },
    formatDate(inputDate) {
      const options = {
        timeZone: 'America/New_York',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      };

      const formattedDate = new Date(inputDate).toLocaleString('en-US', options);
      return formattedDate;
    },
    handleRedirect(id) {
      const serverType = localStorage.getItem('serverType')
      if (serverType == 'staging') {
        this.$router.push(`/skyfi-dashboard/staging/accounts/${id}?for=business`);
      } else if (serverType == 'livestaging') {
        this.$router.push(`/skyfi-dashboard/live-staging/accounts/${id}?for=business`);
      } else if (serverType == 'local') {
        this.$router.push(`/skyfi-dashboard/local/accounts/${id}?for=business`);
      } else {
        this.$router.push(`/skyfi-dashboard/accounts/${id}?for=business`);
      }
    },
    handleAccounts(id) {
      this.accounts_dialog = true;
      this.account_detail = [];
      this.LinkedAccounts(id)
    },
    pageChange() {
      this.Business(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.Business(this.search);
    },
    handleModal(data) {
      this.bId = data?.id,
        this.bName = data?.name,
        this.bLegalName = data?.legal_name,
        this.bEmail = data?.email,
        this.bPhone = data?.phone,
        this.bAdd1 = data?.address1,
        this.bAdd2 = data?.address2,
        this.bCity = data?.city,
        this.bState = data?.state,
        this.bCountry = data?.country,
        this.bZip_code = data?.zip_code,
        this.bKyb_status = data?.kyb_status,
        this.bWebsite = data?.website,
        this.dialog = true;
    },
    async editBusiness() {
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
      const baseurl = this.currentServer?.baseUrl
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          name: this.bName,
          legal_name: this.bLegalName,
          email: this.bEmail,
          phone: this.bPhone,
          address_1: this.bAdd1,
          address_2: this.bAdd2,
          city: this.bCity,
          state: this.bState,
          country: this.bCountry,
          kyb_status: this.bKyb_status,
          zip_code: this.bZip_code,
          website: this.bWebsite,
        }),
        redirect: 'follow',
      }
      try {
        const response = await fetch(baseurl + `admin/businesses/${this.bId}`, requestOptions);
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        console.log(data)
        this.Business();
        this.dialog = false
      } catch (error) {
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else if(error.message) {
          this.onError(error.message);
          this.dialog = false
        }else{
          this.onError('An unknown error occurred.');
          this.dialog = false
        }
      }
    },
    onSuccess(message){
      this.loading = false
      this.snackbarMessage = message;
      this.snackbar = true;
      this.snackbarColor = 'green';
      setTimeout(() => {
        this.snackbarMessage = null;
      }, this.timeout);
    },
    onError(message){
      this.loading = false
      this.snackbarMessage = message;
      this.snackbar = true;
      this.snackbarColor = 'red';
      setTimeout(() => {
        this.snackbarMessage = null;
      }, this.timeout);
    },
    async handleApproveKYB(businessId) {
      try {
        this.loading = true

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/businesses/${businessId}/approve`, {
          method: 'PATCH',
          headers: myHeaders,
        })
        const data = await response.json()
        this.data = JSON.stringify(response)
        if(response.status === 200){
          this.onSuccess(data.message);
          this.Business();
        }
        else {
          this.onError(data.error);
        }
      } catch (error) {
        this.onError('Unauthenticated');
        this.$router.push('/admin-login');
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.Business();
    }, 20);
  },
  watch: {
    $route() {
      this.message = 'Loading... Please wait'
      this.loading = true
      this.search = ''
      this.Business()
    },
  },
};
</script>